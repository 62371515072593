@font-face {
    font-family: "Nunito Sans";
    src: url("/fonts/NunitoSans-Regular.ttf") format("truetype");
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "Nunito Sans";
    src: url("/fonts/NunitoSans-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: "Nunito Sans";
    src: url("/fonts/NunitoSans-Bold.ttf") format("truetype");
    font-weight: 700;
    font-display: swap;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    ::-moz-selection {
        @apply text-white bg-blue-700;
    }

    ::selection {
        @apply text-white bg-blue-700;
    }
}

@layer components {
    button:active:not(.flickity-button),
    .btn:active:not(.flickity-button) {
        @apply bg-blue-500;
        transform: scale(0.95);
    }

    button,
    .btn {
        @apply inline-flex items-center px-4 py-2 border border-transparent text-sm font-bold rounded-md shadow-sm text-white bg-blue-700 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500;
    }

    .btn.btn--secondary {
        @apply bg-white hover:bg-gray-200 border-gray-200 text-gray-600;
    }

    .btn.btn--big {
        @apply px-6 py-3 text-base;
    }

    .btn.btn--link {
        @apply border-none p-0 bg-white hover:bg-white text-gray-600 hover:underline hover:text-gray-500 shadow-none;
    }

    /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
    @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        .backdrop-filter-fallback {
            background-color: rgba(255, 255, 255, 0.9);
        }
    }
}

@import "_forms.css";
